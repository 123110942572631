import { template as template_67f0d1639722433c9eaf75ebb256abfd } from "@ember/template-compiler";
const FKText = template_67f0d1639722433c9eaf75ebb256abfd(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
