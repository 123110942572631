import { template as template_ad4b14bc353448f5b03b5d23a81911e2 } from "@ember/template-compiler";
const FKControlMenuContainer = template_ad4b14bc353448f5b03b5d23a81911e2(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
